//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// https://markus.oberlehner.net/blog/lazy-loading-responsive-images-with-vue/

// requires Object.assign, IntersectionObserver polyfill
import lozad from 'lozad';

export default {
  name: 'BaseImage',
  props: {
    backgroundColor: {
      type: String,
      // cannot set background-color for transparent png
      // default: '#788287',
      default: 'transparent',
    },
    lazySrc: {
      type: String,
      default: null,
    },
    lazySrcset: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    loading: { // HTML <img loading="lazyload">?
      type: String,
      default: null,
    },
    errorImgSrc: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isNativeLazyLoadingSupported: process.server
        ? false // false for nuxt.ssr
        : 'loading' in HTMLImageElement.prototype,
      isLoading: true,
    };
  },
  computed: {
    imageSource() {
      if (this.isNativeLazyLoadingSupported) {
        return this.lazySrc;
      }
      return 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
    },
    imageSourceSet() {
      if (this.isNativeLazyLoadingSupported) {
        return this.lazySrcset;
      }
      return '';
    },
    aspectRatio() {
      // Calculate the aspect ratio of the image
      // if the width and the height are given.
      if (!this.width || !this.height) return null;

      return (this.height / this.width) * 100;
    },
    style() {
      // The background color is used as a
      // placeholder while loading the image.
      // You can use the dominant color of the
      // image to improve perceived performance.
      // See: https://manu.ninja/dominant-colors-for-lazy-loading-images/
      const style = { backgroundColor: this.backgroundColor };

      if (this.width) style.width = `${this.width}px`;

      // If the image is still loading and an
      // aspect ratio could be calculated, we
      // apply the calculated aspect ratio by
      // using padding top.
      const applyAspectRatio = this.isLoading && this.aspectRatio;
      if (applyAspectRatio) {
        // Prevent flash of unstyled image
        // after the image is loaded.
        style.height = 0;
        // Scale the image container according
        // to the aspect ratio.
        style.paddingTop = `${this.aspectRatio}%`;
      }

      return style;
    },
  },
  // @todo handle reactive lazySrc update
  // watch: {
  //   lazySrc(value) {
  //   },
  // },
  mounted() {
    // const isNativeLazyLoadingSupported = 'loading' in HTMLImageElement.prototype;

    // this.isNativeLazyLoadingSupported = false;

    if (this.isNativeLazyLoadingSupported) {

      // just add loading="lazyload"

    } else {
      // As soon as the <img> element triggers
      // the `load` event, the loading state is
      // set to `false`, which removes the apsect
      // ratio we've applied earlier.
      const setLoadingState = () => {
        this.isLoading = false;
      };
      this.$el.addEventListener('load', setLoadingState);
      // We remove the event listener as soon as
      // the component is destroyed to prevent
      // potential memory leaks.
      this.$once('hook:destroyed', () => {
        this.$el.removeEventListener('load', setLoadingState);
      });

      // We initialize Lozad.js on the root
      // element of our component.
      const observer = lozad(this.$el);
      observer.observe();
    }
  },
  methods: {
    errorImg(e) {
      this.$emit('error', e);
      if (this.errorImgSrc) {
        // fix the loop that occurs when the errorImgSrc returns an error
        if (!e.target.hasAttribute('data-error')) {
          e.target.setAttribute('data-error', true);
          e.target.onerror = null;
          e.target.src = this.errorImgSrc;
        }
      }
    },
    onLoad(e) {
      this.$emit('load', e);
    },
  },
};
